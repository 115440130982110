export function getWarehouseList(data) {
  return {
    url: '/app/operation/protection/warehouse/home/auth_warehouse_list',
    data,
  }
}
export function getWarehouseListV2(data) {
  return {
    url: '/app/operation/protection/service/auth/transfer_auth_list',
    data,
  }
}
