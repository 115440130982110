<template>
  <section class="xa-container">
    <van-sticky>
      <van-search
        v-model="filterWarehouse"
        placeholder="搜索网点"
        v-show="warehouseList.length"
      />
    </van-sticky>
    <van-radio-group v-model="warehouseGuid">
      <van-cell-group>
        <van-cell
          v-for="item in calcWarehouseList"
          :title="item.name"
          clickable
          @click="selectWarehouse(item)"
          :key="item.guid"
        >
          <van-radio slot="right-icon" :name="item.guid" />
        </van-cell>
      </van-cell-group>
    </van-radio-group>
  </section>
</template>
<script>
// 选择服务单转移对象
import basePage from '@/mixins/basePage'
import { Cell, CellGroup, RadioGroup, Radio, Search, Sticky } from 'vant'
export default {
  mixins: [basePage],
  config: {
    title: '转让订单',
    canRefresh: false
  },
  components: {
    VanRadioGroup: RadioGroup,
    VanRadio: Radio,
    VanCell: Cell,
    VanCellGroup: CellGroup,
    VanSearch: Search,
    VanSticky: Sticky
  },
  data() {
    return {
      filterWarehouse: '',
      warehouseGuid: '',
      warehouseList: []
    }
  },
  computed: {
    calcWarehouseList() {
      if (this.filterWarehouse) {
        return this.warehouseList.filter(
          item => item.text.indexOf(this.filterWarehouse) > -1
        )
      } else {
        return this.warehouseList
      }
    }
  },
  methods: {
    selectWarehouse(item) {
      this.cb(item)
      this.$router.go(-1)
    },
    async onSubmit() {
      this.$_submitDataWidthUi(this.submitData, '确定转移订单？')
    }
  },
  mounted() {
    if (this.$route.params.warehouseList === undefined) {
      return this.$router.go(-1)
    }
    this.warehouseList = this.$route.params.warehouseList
  },
  activated() {
    if (
      this.warehouseList.length === 0 &&
      this.$route.params.warehouseList !== undefined
    ) {
      this.warehouseList = this.$route.params.warehouseList
    }
    this.warehouseGuid = this.$route.query.guid
    this.cb = this.$route.params.cb
  }
}
</script>
<style lang="scss"></style>
