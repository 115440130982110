// 【申请】获取网点的保障专员
export function getProtectorsByGuid(data) {
  return {
    url: '/app/member/protection/service/home/get_protectors',
    data
  }
}

export function transferService(data) {
  return {
    method: 'post',
    url: '/app/operation/protection/service/auth/transfer',
    data
  }
}

// 【申请配件】获取配件申请记录列表
export function GetMpList(data) {
  return {
    url: '/app/operation/protection/service/v2/mp_list',
    data
  }
}

// 【退料】获取可退料物料清单
export function GetMrSelectMaterials(data) {
  return {
    url: '/app/operation/protection/service/v2/mr_select_materials',
    data
  }
}

// 【退料】获取可退料物料清单 多个单
export function GetMrSelectMaterialsMulti(data) {
  return {
    url: '/app/operation/protection/service/v2/mr_select_material_multi',
    data
  }
}

// 【退料】提交退料申请
export function ApplyMaterialReturn(data) {
  return {
    method: 'post',
    url: '/app/operation/protection/service/v2/mr_apply',
    data
  }
}

// 【退料】提交退料申请--多个单
export function ApplyMaterialReturnMulti(data) {
  return {
    method: 'post',
    url: '/app/operation/protection/service/v2/mr_apply_multi',
    data
  }
}

// 【退料】提交退料申请--多个单
export function SubmitLossInfo(data) {
  return {
    method: 'post',
    url: '/app/operation/protection/service/loss/submit_loss_info',
    data
  }
}

