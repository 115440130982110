<template>
  <div class="xa-view empty-box">
    <i class="iconfont icon-nodata"></i>
    <p>{{ content }}</p>
  </div>
</template>
<script>
export default {
  props: {
    content: {
      type: String,
      default: '暂无数据'
    }
  }
}
</script>
<style lang="scss" scoped>
.empty-box {
  height: 20vh;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  .iconfont {
    font-size: 80px;
    margin-bottom: 16px;
  }
  p {
    max-width: 90%;
    line-height: $default-line-height;
  }
}
</style>
