<template>
  <section class="xa-container">
    <van-sticky v-if="warehouseList.length">
      <van-cell
        class="xa-line__bottom"
        @click="goToSelectWarehouse"
        :title="curWarehouseTitle"
        is-link
      >
      </van-cell>
    </van-sticky>
    <van-search
      v-model="filterProtector"
      placeholder="搜索保障人员"
      v-show="protectorsOptions.length"
    />
    <van-radio-group v-model="protectorsGuid">
      <van-cell-group>
        <van-cell
          v-for="item in calcProtectorsOptions"
          :title="item.name"
          clickable
          @click="protectorsGuid = item.guid"
          :key="item.guid"
        >
          <van-radio slot="right-icon" :name="item.guid" />
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <EmptyItem
      v-if="calcProtectorsOptions.length == 0 && !isLoading"
      content="该网点下没有保障人员"
    />
    <EnsureFootBtn :disabled="!protectorsGuid" @submit="onSubmit" />
  </section>
</template>
<script>
// 选择服务单转移对象
import basePage from '@/mixins/basePage'
import { Cell, CellGroup, RadioGroup, Radio, Sticky, Search } from 'vant'
import EmptyItem from '@/components/diylist/EmptyItem'
import EnsureFootBtn from '@/components/EnsureFootBtn'
import { getWarehouseListV2 } from '@/apis/warehouse'
import { getProtectorsByGuid, transferService } from '@/apis/protection'
export default {
  mixins: [basePage],
  config: {
    title: '转让订单',
    fetchDataFn: 'initView',
    canRefresh: false
  },
  components: {
    VanRadioGroup: RadioGroup,
    VanRadio: Radio,
    VanCell: Cell,
    VanCellGroup: CellGroup,
    VanSticky: Sticky,
    VanSearch: Search,
    EnsureFootBtn,
    EmptyItem
  },
  data() {
    return {
      filterProtector: '',
      filterWarehouse: '',
      warehouseGuid: '',
      warehouseList: [],
      protectorsGuid: '',
      protectorsOptions: [],
      query: {},
      isLoading: true
    }
  },
  computed: {
    curWarehouseTitle() {
      if (this.warehouseGuid) {
        return this.warehouseList.find(
          item => item.value === this.warehouseGuid
        ).name
      } else {
        return '请选择'
      }
    },
    calcProtectorsOptions() {
      if (this.filterProtector) {
        return this.protectorsOptions.filter(
          item => item.name.indexOf(this.filterProtector) > -1
        )
      } else {
        return this.protectorsOptions
      }
    }
  },
  watch: {
    warehouseGuid() {
      this.protectorsGuid = ''
      this.$_fetchDataWidthUi(this.fetchProtectors())
    }
  },
  methods: {
    goToSelectWarehouse() {
      this.$router.push({
        name: 'select-warehouse',
        query: {
          guid: this.warehouseGuid
        },
        params: {
          warehouseList: this.warehouseList,
          cb: item => {
            this.selectWarehouse(item)
          }
        }
      })
    },
    selectWarehouse(item) {
      this.warehouseGuid = item.value
      this.filterWarehouse = this.filterProtector = ''
    },
    async onSubmit() {
      this.$_submitDataWidthUi(this.submitData, '确定转移订单？')
    },
    async submitData() {
      await this.$_request(
        transferService({
          protector: this.protectorsGuid,
          warehouse: this.warehouseGuid,
          ...this.query
        })
      )
      this.$router.go(-1)
    },
    async fetchProtectors() {
      this.isLoading = true
      this.protectorsOptions = await this.$_request(
        getProtectorsByGuid({
          ...this.query,
          auth_warehouse_guid: this.warehouseGuid
        })
      )
      this.filterProtector = ''
      this.isLoading = false
    },
    async initView() {
      const { data } = this.$_extractRequestInfoFormRoute()
      this.query = data
      this.warehouseList = await this.$_fetchDataWidthUi(
        getWarehouseListV2(this.query)
      )
      this.warehouseList = this.warehouseList.map(item => {
        item.text = item.name
        item.value = item.guid
        return item
      })
      this.warehouseGuid = this.warehouseList[0] && this.warehouseList[0].guid // 默认就是第一个
    }
  }
}
</script>
<style lang="scss"></style>
